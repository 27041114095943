import { useNavigate, useParams } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import { useUserContext } from '../../context';
import { useState, useEffect } from 'react';
import {
    Dialog,
    DialogBody

} from "@material-tailwind/react";
import { ClockLoader } from 'react-spinners';
import { MdSunny } from "react-icons/md";
import { PiMoonFill } from "react-icons/pi";
import { startOfMonth, endOfMonth, eachDayOfInterval, format } from "date-fns";

export default function Register() {
    const { selectedMonth } = useParams();
    const { fetchAccessToken, defaultHotel } = useUserContext();
    const { getAttendancesByHotel, getEmployees } = useApi();
    const [attendanceDayLogs, setAttendanceDayLogs] = useState([]);
    const [attendanceNightLogs, setAttendanceNightLogs] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const dateObject = new Date(selectedMonth + "-01");
    useEffect(() => {
        let getEmployeesInternal = async (hotelId) => {
            setIsLoading(true);
            try {
                const token = await fetchAccessToken();
                const data = await getEmployees(hotelId, token);
                if (data && data.length) {
                    setEmployees(data);
                } else {
                    setEmployees([]);
                }
            } catch (ex) {
                setEmployees([]);
            } finally {
                setIsLoading(false);
            }
        }
        if (defaultHotel) {
            getEmployeesInternal(defaultHotel);
        }
    }, [defaultHotel]);


    useEffect(() => {
        const getAttendancesInternal = async (defaultHotel) => {
            setIsLoading(true);
            try {
                const token = await fetchAccessToken();
                const start = getEpoch(getStartOfMonth(dateObject), "00:00");
                const end = getEpoch(getEndOfMonth(dateObject), "23:59");
                const data = await getAttendancesByHotel(defaultHotel, start, end, token);
                if (data && data.length) {
                    setAttendanceDayLogs(data.filter(d => d.shift === "DAY"));
                    setAttendanceNightLogs(data.filter(d => d.shift === "NIGHT"));
                } else {
                    setAttendanceDayLogs([]);
                    setAttendanceNightLogs([]);
                }
            } catch (ex) {
                setAttendanceDayLogs([]);
                setAttendanceNightLogs([]);

            } finally {
                setIsLoading(false);
            }
        };
        if (defaultHotel && selectedMonth) {
            getAttendancesInternal(defaultHotel);
        }
    }, [defaultHotel]);

    function getEpoch(date, time, seconds = 0) {
        const [hours, minutes] = time.split(':').map(Number);
        const combinedDate = new Date(date);
        combinedDate.setHours(hours);
        combinedDate.setMinutes(minutes);
        combinedDate.setSeconds(seconds);
        combinedDate.setMilliseconds(seconds * 100);

        return combinedDate.getTime();
    }

    function getStartOfMonth(date) {
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        return startDate;
    }

    function getEndOfMonth(date) {
        const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return endDate;
    }

    const daysInMonth = eachDayOfInterval({
        start: startOfMonth(dateObject),
        end: endOfMonth(dateObject)
    });

    const getTotalShifts = (employeeId) => {
        let dayCount = attendanceDayLogs.filter(a => a.employeeId === employeeId).reduce((total, item) => {
            return total + (item.shift ? 1 : 0);
        }, 0);
        let nightCount = attendanceNightLogs.filter(a => a.employeeId === employeeId).reduce((total, item) => {
            return total + (item.shift ? 1 : 0);
        }, 0);
        return dayCount + nightCount;
    };

    return (
        <div>
            <div className="flex justify-center text-lg">
                {selectedMonth}
            </div>
            <div className="overflow-auto">
                <table className="table-auto border-collapse border border-gray-400 w-full">
                    <thead>
                        <tr className="sticky top-0">
                            <td className="sticky left-0 bg-white px-2 py-2 border border-gray-400">Employee</td>
                            {daysInMonth.map((date) => (
                                <td key={date} className="border bg-white border-gray-400 px-2 py-2 text-center text-sm">
                                    <div className="flex flex-col" >
                                        <span> {format(date, "d")}</span>
                                        <span className="text-xs"> {format(date, "EEE").slice(0, 2)}</span>
                                    </div>
                                </td>
                            ))}
                            <td className="sticky left-0 bg-white px-2 py-2 border border-gray-400">Total</td>
                        </tr>
                    </thead>
                    <tbody>
                        {employees
                            .filter(emp => emp.primaryLocation.toLowerCase() === defaultHotel.toLowerCase())
                            .sort((a, b) => a.role.localeCompare(b.role))
                            .map((employee, index) => (
                                <tr key={index}>
                                    <td className="sticky left-0 bg-white px-2 py-2 border border-gray-400 text-sm">
                                            {employee.name} ({employee.role})
                                    </td>
                                    {daysInMonth.map((date) => {
                                        const dateStr = format(date, "yyyy-MM-dd");
                                        const isDayShift = attendanceDayLogs.find(a => a.employeeId === employee.id && a.date === dateStr);
                                        const isNightShift = attendanceNightLogs.find(a => a.employeeId === employee.id && a.date === dateStr);
                                        return (
                                            <td key={dateStr} className="border bg-white border-gray-400 px-2 py-2">
                                                <div>
                                                    <MdSunny className={`${isDayShift ? "" : "invisible"} text-red-500`} size={15} />
                                                    <PiMoonFill className={`${isNightShift ? "" : "invisible"} text-black-500`} size={15} />
                                                </div>
                                            </td>
                                        );
                                    })}
                                    <td className="sticky left-0 bg-white px-2 py-2 border border-gray-400 text-sm">
                                        {getTotalShifts(employee.id)}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
            <Dialog open={isLoading} className="bg-transparent shadow-none" size="md">
                <DialogBody className="bg-transparent shadow-none flex justify-center">
                    <ClockLoader color="#2be60d" />
                </DialogBody>
            </Dialog>
        </div>
    );
}