import React, { useState, useEffect } from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Dialog,
    DialogBody,
    Typography
} from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../context';
import { useApi } from '../../hooks/useApi';
import { ClockLoader } from 'react-spinners';

export default function AddLaundry() {
    const navigate = useNavigate();
    const { getLaundryItems, getMissingLaundry, saveLaundry, updateLaundries } = useApi();
    const { fetchAccessToken, defaultHotel, user, isAdmin } = useUserContext();
    const [isLoading, setIsLoading] = useState(false);
    const [laundryItems, setLaundryItems] = useState([]);
    const [pickupItems, setPickupItems] = useState([]);
    const [missingLaundryItems, setMissingLaundryItems] = useState([]);
    const [missingLaundry, setMissingLaundry] = useState(null);

    useEffect(() => {
        let getLaundryItemsInternal = async (hotelId) => {
            setIsLoading(true);
            try {
                const token = await fetchAccessToken();
                const data = await getLaundryItems(hotelId, token);
                if (data && data.length) {
                    setLaundryItems(data);
                } else {
                    setLaundryItems([]);
                }
                const ml = await getMissingLaundry(hotelId, token);
                if (ml) {
                    setMissingLaundry(ml);
                    setMissingLaundryItems(ml.missingItems);
                }
            } catch (ex) {
                alert(ex);

            } finally {
                setIsLoading(false);
            }
        }
        if (defaultHotel) {
            getLaundryItemsInternal(defaultHotel);
        }

    }, [defaultHotel]);

    useEffect(() => {
        if (laundryItems) {
            let pickupDate = formatDate();
            setPickupItems(laundryItems.map(item => ({ name: item.itemName, count: 0, unitPrice: item.unitCharge, pickupDate: pickupDate })));
        }
    }, [laundryItems]);

    let getMissingItemCount = (itemName) => {
        const missingItem = missingLaundryItems.find(item => item.name === itemName);
        if (missingItem) {
            return missingItem.count;
        }
        return 0;
    };

    let handleSubmitInternal = async () => {
        try {
            setIsLoading(true);
            const laundryRequest = {
                status: "PICKUP",
                pickupDate: formatDate(),
                pickupTime: getCurrentTime(),
                returnDate: "",
                returnTime: "",
                createdOn: Date.now(),
                previousMissedItems: missingLaundryItems,
                pickupItems: pickupItems,
                returnItems: [],
                missingItems: [],
                remarks: ""
            };
            const token = await fetchAccessToken();
            if (missingLaundry) {
                missingLaundry.missingItems = [];
                missingLaundry.remarks = missingLaundry.remarks + "," + "Added missing Items to pickup on " + laundryRequest.pickupDate;
                await updateLaundries(defaultHotel, laundryRequest, missingLaundry, token);
            } else {
                await saveLaundry(defaultHotel, laundryRequest, token);
            }
            alert("Pickup basket has been created.");
            navigate("/manager/laundry");
        } catch (ex) {
            alert("Error occurred while creating basket." + ex);
        } finally {
            setIsLoading(false);
        }

    }

    function formatDate(daysAdd = 0) {
        const date = new Date();
        date.setDate(date.getDate() + daysAdd);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    function getCurrentTime(hoursAdd = 0) {
        const now = new Date();
        const hours = String(now.getHours() + hoursAdd).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return hours + ':' + minutes;
    }

    let setItemCount = (index, count) => {
        setPickupItems(pickupItems.map((item, i) => {
            if (index === i) {
                return { ...item, count };
            } else {
                return item;
            }
        }
        ));
    };


    let handleCanelInternal = () => {
        const result = window.confirm("Do you want to discard the pickup?");
        if (result) {
            navigate("/manager/laundry");
        }
    };


    return (
        <div>
            <Card className="h-full w-full">
                <CardHeader variant="gradient" color="gray" className="mt-6 mb-8 p-6 flex flex-row justify-between">
                    <Typography variant="h6" color="white">
                        Create Pickup
                    </Typography>
                </CardHeader>
                <CardBody>
                    <div className="relative w-full mb-3">
                        <div className="relative w-full mb-3">
                            <div className="flex flex-wrap gap-1 items-center mb-3">
                                <div className="flex-1 min-w-[150px]">
                                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Item Name</label>
                                </div>
                                <div className="flex-1 min-w-[50px]">
                                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Count <span className="text-red-500">*</span></label>
                                </div>
                                <div className="flex-1 min-w-[50px]">
                                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Arrears</label>
                                </div>
                            </div>
                            {pickupItems.map((pickupItem, index) =>
                                <div className="flex flex-wrap gap-1 items-center mb-3" key={index}>
                                    <div className="flex-1 min-w-[150px]">
                                        <Typography
                                            variant="small"
                                            className="flex items-center font-normal text-blue-gray-600"
                                        >
                                            {pickupItem.name}
                                        </Typography>
                                    </div>
                                    <div className="flex-1 min-w-[50px]">
                                        <input type="number" id={`pickupItems.${index}.count`} name={`pickupItems.${index}.count`}
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            value={pickupItem.count} onChange={(event) => setItemCount(index, parseInt(event.target.value, 10))} />
                                    </div>
                                    <div className="flex-1 min-w-[50px]">
                                        <label className="block text-blueGray-600 text-md font mt-1 ml-2">{getMissingItemCount(pickupItem.name)}</label>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="w-full flex justify-end  mb-0 py-6">
                            <button className="bg-blue-500 text-white hover:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150" type="button" onClick={handleSubmitInternal}>
                                Pickup
                            </button>
                            <button className="bg-pink-500 text-white hover:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150" type="button" onClick={handleCanelInternal}>
                                Canel
                            </button>
                        </div>
                    </div>
                </CardBody>
            </Card>
            <Dialog open={isLoading} className="bg-transparent shadow-none" size="md">
                <DialogBody className="bg-transparent shadow-none flex justify-center">
                    <ClockLoader color="#2be60d" />
                </DialogBody>
            </Dialog>
        </div>
    );
}
