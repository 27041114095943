import React, { useEffect, useState } from 'react';
import { ClockLoader } from 'react-spinners';
import { useUserContext } from '../../context';
import {
    Card,
    CardBody,
    CardHeader,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Typography,
    Button
} from "@material-tailwind/react";
import { useApi } from '../../hooks/useApi';

const TABLE_HEAD_ROOM_TYPE = ["S.No", "Name", "Charge"];

export default function LaundryItems() {
    const [isLoading, setIsLoading] = useState(false);
    const [laundryItems, setLaundryItems] = useState([]);
    const { defaultHotel, fetchAccessToken } = useUserContext();
    const { addLaundryItem, getLaundryItems } = useApi();
    const [showAddLaundryPopup, setShowAddLaundryPopup] = useState(false);
    const [category, setCategory] = useState("");
    const [charge, setCharge] = useState(0);

    useEffect(() => {

        let getLaundryItemsInternal = async (hotelId) => {
            setIsLoading(true);
            try {
                const token = await fetchAccessToken();
                const data = await getLaundryItems(hotelId, token);
                if (data && data.length) {
                    setLaundryItems(data);
                } else {
                    setLaundryItems([]);
                }
            } catch (ex) {

            } finally {
                setIsLoading(false);
            }
        }
        if (defaultHotel) {
            getLaundryItemsInternal(defaultHotel);
        }

    }, [defaultHotel]);

    let getLaundryItemsInternal = async () => {
        setIsLoading(true);
        try {
            const token = await fetchAccessToken();
            const data = await getLaundryItems(defaultHotel, token);
            if (data && data.length) {
                setLaundryItems(data);
            } else {
                setLaundryItems([]);
            }
        } catch (ex) {

        } finally {
            setIsLoading(false);
        }
    }

    const addLaundryItemInternal = async () => {
        if(!category || !charge) {
            return;
        }
        setShowAddLaundryPopup(false);
        setIsLoading(true);
        try {
            const token = await fetchAccessToken();
            const laundryItem = {
                itemName: category,
                unitCharge: charge
            };
            await addLaundryItem(defaultHotel, laundryItem, token);
            await getLaundryItemsInternal();
            setCategory("");
            setCharge(0);
        } catch (ex) {
            alert("Error saving Laundry Item.")
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <div>
            <Card className="h-full w-full">
                <CardHeader variant="gradient" color="gray" className="mt-6 mb-8 p-6 flex flex-row justify-between">
                    <Typography variant="h6" color="white">
                        Laundry Items
                    </Typography>
                    <button className="shadow bg-blue-500 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white font-bold text-xs py-2 px-4 rounded" type="button"
                        onClick={() => setShowAddLaundryPopup(true)}>
                        Add Item
                    </button>
                </CardHeader>
                <CardBody className="overflow-scroll px-0 pt-0 pb-2">
                    <table className="mt-0 w-full min-w-max table-auto text-left">
                        <thead>
                            <tr>
                                {TABLE_HEAD_ROOM_TYPE.map((head) => (
                                    <th
                                        key={head}
                                        className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                                    >
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-90"
                                        >
                                            {head}
                                        </Typography>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {laundryItems.sort((a, b) => a.itemName.localeCompare(b.itemName)).map(
                                ({ itemName, unitCharge, }, index) => {
                                    const isLast = index === laundryItems.length - 1;
                                    const classes = isLast
                                        ? "p-4"
                                        : "p-4 border-b border-blue-gray-50";

                                    return (
                                        <tr key={index}>
                                            <td className={classes}>
                                                <div className="flex items-center gap-3">
                                                    <div className="flex flex-col">
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal"
                                                        >
                                                            {index + 1}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <div className="flex flex-col">
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {itemName}
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <div className="flex flex-col">
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {unitCharge}.00
                                                    </Typography>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                },
                            )}
                        </tbody>
                    </table>
                </CardBody>
            </Card>
            <Dialog open={isLoading} className="bg-transparent shadow-none" size="md">
                <DialogBody className="bg-transparent shadow-none flex justify-center">
                    <ClockLoader color="#2be60d" />
                </DialogBody>
            </Dialog>
            {showAddLaundryPopup &&
                <Dialog open={showAddLaundryPopup}>
                    <DialogHeader>Add Item</DialogHeader>
                    <DialogBody>
                        <div className="grid grid-cols-2 gap-4">
                            <div className="flex flex-col">
                                <label for="category" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Item Name<span className="text-red-500">*</span></label>
                                <input type="text" id="category" name="category" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    onChange={(e) => setCategory(e.target.value)}
                                    value={category}  />
                            </div>
                            <div className="flex flex-col">
                                {" "}
                            </div>
                            <div className="flex flex-col">
                                <label for="charge" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Unit Charge<span className="text-red-500">*</span></label>
                                <input type="number" id="charge" name="charge" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    onChange={(e) => setCharge(parseInt(e.target.value, 10))} 
                                    value={charge} />
                            </div>
                        </div>
                    </DialogBody>
                    <DialogFooter>
                        <Button
                            variant="text"
                            color="red"
                            onClick={() => setShowAddLaundryPopup(false)}
                            className="mr-1"
                        >
                            <span>Cancel</span>
                        </Button>
                        <Button variant="gradient" color="green" onClick={addLaundryItemInternal}>
                            <span>Save</span>
                        </Button>
                    </DialogFooter>
                </Dialog>
            }
        </div>
    );
}