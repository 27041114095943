import { useFormik } from 'formik';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useUserContext } from '../../context';
import { useApi } from "../../hooks/useApi";
import { Checkbox } from "@material-tailwind/react";


function HotelDetails() {
    const { updateHotel, getHotels } = useApi();
    const navigate = useNavigate();
    const { hotelId } = useParams();
    const { assignments, fetchAccessToken } = useUserContext();
    let hotel = assignments?.hotels.find(hotel => hotel.id.toLowerCase() === hotelId.toLowerCase());
    let { name, address, mobile, gst, nextCardNo, bankDetails, groupName, invoicePrefix, nextTaxInvoiceNo, isGuestWhatsApp, isGuestSMS, laundryContact } = hotel ?? { name: "", address: "", mobile: "", gst: "", nextCardNo: "0", bankDetails: "", groupName: "", invoicePrefix: "", nextTaxInvoiceNo: "0", isGuestWhatsApp: false, isGuestSMS: false, laundryContact: "NA" };

    const formik = useFormik({
        initialValues: { name, address, mobile, gst, nextCardNo, bankDetails, groupName, invoicePrefix, nextTaxInvoiceNo, isGuestWhatsApp, isGuestSMS, laundryContact },
        validationSchema: Yup.object({
            name: Yup.string().required('Hotel name is required'),
            address: Yup.string().required('Hotel Address is required'),
            mobile: Yup.string().required('Hotel mobile is required'),
            gst: Yup.string().required('Hotel GST is required'),
            nextCardNo: Yup.number().typeError('Card No seed must be a number').positive('Card No seed must be a positive number').required('Card No seed is required'),
            groupName: Yup.string().required('Hotel groupName is required'),
            invoicePrefix: Yup.string().required('Invoice Prefix is required')
        }),
        onSubmit: async (values, actions) => {
            try {
                const token = await fetchAccessToken();
                await updateHotel(hotelId, values, token)
                alert("Hotel details updated successfully.");
                navigate(-1);
            } catch (ex) {
                alert("Hotel details updated failed. Please contact support." + ex);
            }
        }
    });

    const handleSubmit = () => {
        formik.handleSubmit();
    };

    return (
        <form>
            <section className="py-1 bg-blueGray-50">
                <div className="w-full lg:w-8/12 px-4 mx-auto mt-6">
                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                        <div className="rounded-t bg-white mb-0 px-6 py-6">
                            <div className="text-center flex justify-between">
                                <h6 className="text-blueGray-700 text-xl font-bold">
                                    {name}
                                </h6>
                                <div>
                                    <button className="shadow bg-blue-500 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white font-bold text-xs py-2 px-4  mr-2 rounded" type="button"
                                        onClick={() => navigate(`/admin/hotels/${hotelId}/rooms`)}>
                                        Rooms
                                    </button>
                                    <button className="shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold text-xs py-2 px-4 rounded" type="button"
                                        onClick={() => navigate(`/admin/managers`)}>
                                        Managers
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                Basic Information
                            </h6>
                            <div className="flex flex-wrap">
                                <div className="relative w-full mb-3 px-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                            Full Name
                                        </label>
                                        <input id="name" name="name" type="text" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            onChange={formik.handleChange} onBlur={formik.handleBlur}
                                            value={formik.values.name} />
                                        {formik.touched.name && formik.errors.name ? (
                                            <div className="text-red-500">{formik.errors.name}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                            Group Name
                                        </label>
                                        <input type="text" id="groupName" name="groupName" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.groupName} readOnly={true} />
                                        {formik.touched.groupName && formik.errors.groupName ? (
                                            <div className="text-red-500">{formik.errors.groupName}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                            Phone Number(s)
                                        </label>
                                        <input id="mobile" name="mobile" type="text" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.mobile} />
                                        {formik.touched.mobile && formik.errors.mobile ? (
                                            <div className="text-red-500">{formik.errors.mobile}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                            GST Number
                                        </label>
                                        <input type="text" id="gst" name="gst" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.gst} />
                                        {formik.touched.gst && formik.errors.gst ? (
                                            <div className="text-red-500">{formik.errors.gst}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                            Laundry Contact
                                        </label>
                                        <input type="text" id="gst" name="gst" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.laundryContact} />
                                    </div>
                                </div>
                                <div className="w-full lg:w-12/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                            Address
                                        </label>
                                        <textarea type="text" id="address" name="address" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" rows="4" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.address} />
                                        {formik.touched.address && formik.errors.address ? (
                                            <div className="text-red-500">{formik.errors.address}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="w-full lg:w-12/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                            Bank Details
                                        </label>
                                        <textarea type="text" id="bankDetails" name="bankDetails" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" rows="4" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.bankDetails} />
                                        {formik.touched.bankDetails && formik.errors.bankDetails ? (
                                            <div className="text-red-500">{formik.errors.bankDetails}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            <hr className="mt-6 border-b-1 border-blueGray-300" />

                            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                Other Details
                            </h6>
                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-6/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                            Next Card No
                                        </label>
                                        <input type="number" id="nextCardNo" name="nextCardNo" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.nextCardNo} />
                                        {formik.touched.nextCardNo && formik.errors.nextCardNo ? (
                                            <div className="text-red-500">{formik.errors.nextCardNo}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                            Invoice Prefix
                                        </label>
                                        <input type="text" id="invoicePrefix" name="invoicePrefix" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.invoicePrefix} />
                                        {formik.touched.invoicePrefix && formik.errors.invoicePrefix ? (
                                            <div className="text-red-500">{formik.errors.invoicePrefix}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                            Check-In time
                                        </label>
                                        <input type="text" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" onChange={() => { }} value="any" />
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                            Checkout time
                                        </label>
                                        <input type="text" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" onChange={() => { }} value="any" />
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                            Guest Communication
                                        </label>
                                        <Checkbox label="WhatsApp" onChange={formik.handleChange} checked={formik.values.isGuestWhatsApp} disabled={true}/>
                                        <Checkbox label="SMS" onChange={formik.handleChange} checked={formik.values.isGuestSMS} disabled={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex justify-end  mb-0 px-6 py-6">
                            <button className="bg-pink-500 text-white hover:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150" type="button" onClick={handleSubmit}>
                                Save
                            </button>
                        </div>
                    </div>

                </div>
            </section>
        </form>
    )
}

export default HotelDetails;
