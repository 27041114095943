import {
    Card,
    CardBody,
    CardHeader,
    Dialog,
    DialogBody,
    IconButton,
    Tooltip,
    Typography
} from "@material-tailwind/react";
import React, { useState } from 'react';
import { HiPencil } from "react-icons/hi";
import { MdDelete } from "react-icons/md";
import { useParams } from 'react-router-dom';
import { ClockLoader } from 'react-spinners';
import { useUserContext } from '../../context';
import { useApi } from "../../hooks/useApi";
import RoomCategoryPopup from "./room-category-popup";
import RoomPopup from './room-popup';

const TABLE_HEAD_ROOM_TYPE = ["Category", "Price", "GST", "Capacity", "Extra bed", "Actions"];

const TABLE_HEAD_ROOM = ["Room No", "Actions"];


export default function Rooms() {
    const [isLoading, setIsLoading] = useState(false);
    const { hotelId } = useParams();
    const { addRoomType, deleteRoomType, addRoom, deleteRoom } = useApi();
    const { assignments, fetchAccessToken } = useUserContext();
    let hotel = assignments?.hotels.find(hotel => hotel.id.toLowerCase() === hotelId.toLowerCase());
    let { rooms, roomTypes } = hotel ?? { rooms: [], roomTypes: [] };

    const [isOpenRoomTypePopup, setIsOpenRoomTypePopup] = useState(false);
    const [isOpenRoomPopup, setIsOpenRoomPopup] = useState(false);
    const [initialValueRoomType, setInitialValuesRoomType] = useState({
        category: "",
        price: 0,
        gst: 0,
        capacity_adults: 0,
        capacity_kids: 0,
        extraBedCharges: 0
    });
    const [initialValueRoom, setInitialValuesRoom] = useState({
        roomNo: "",
        category: "",
        price: 0,
        gst: 0,
        capacity_adults: 0,
        capacity_kids: 0,
        extraBedCharges: 0
    });
    const onSubmitRoomType = async (values) => {
        try {
            setIsLoading(true);
            const data = {
                category: values.category,
                price: values.price,
                gst: values.gst,
                capacity: {
                    adults: values.capacity_adults,
                    kids: values.capacity_kids
                },
                extraBedCharges: values.extraBedCharges
            };
            const token = await fetchAccessToken();
            await addRoomType(hotelId, data, token)
            alert("Room Category details updated successfully.");
            window.location.reload();
        } catch (ex) {
            alert(ex);
            alert("Hotel details updated failed. Please contact support." + ex);
        }
        finally {
            setIsLoading(false);
        }
    }

    const onSubmitRoom = async (values) => {
        try {
            setIsLoading(true);
            const data = {
                roomNo: values.roomNo
            };
            const token = await fetchAccessToken();
            await addRoom(hotelId, data, token)
            alert("Room details updated successfully.");
            window.location.reload();
        } catch (ex) {
            alert(ex);
            alert("Hotel updated failed. Please contact support." + ex);
        } finally {
            setIsLoading(false);
        }
    }

    let handleOpenRoomTypePopup = () => {
        setInitialValuesRoomType({
            category: "",
            price: 0,
            gst: 0,
            capacity_adults: 0,
            capacity_kids: 0,
            extraBedCharges: 0
        });
        setIsOpenRoomTypePopup(true);
    };

    let handleOpenRoomPopup = () => {
        setInitialValuesRoomType({
            roomNo: "",
            category: "",
            price: 0,
            gst: 0,
            capacity_adults: 0,
            capacity_kids: 0,
            extraBedCharges: 0
        });
        setIsOpenRoomPopup(true);
    };

    let handleEditRoomType = (index) => {
        let roomType = roomTypes[index];
        setInitialValuesRoomType({
            category: roomType.category,
            price: roomType.price,
            gst: roomType.gst,
            capacity_adults: roomType.capacity.adults,
            capacity_kids: roomType.capacity.kids,
            extraBedCharges: roomType.extraBedCharges
        });
        setIsOpenRoomTypePopup(true);
    };

    let handleEditRoom = (index) => {
        let room = rooms[index];
        setInitialValuesRoom({
            roomNo: room.roomNo,
            category: room.category,
            price: room.price,
            gst: room.gst,
            capacity_adults: room.capacity.adults,
            capacity_kids: room.capacity.kids,
            extraBedCharges: room.extraBedCharges
        });
        setIsOpenRoomPopup(true);
    };

    let handleDeleteRoomType = async (index) => {
        const result = window.confirm("You are about to delete the Room Category.");
        if (result) {
            let roomType = roomTypes[index];
            try {
                const token = await fetchAccessToken();
                await deleteRoomType(hotelId, roomType.category, token)
                alert("Room Category details deleted successfully.");
                window.location.reload();
            } catch (ex) {
                alert(ex);
                alert("Hotel details delete failed. Please contact support." + ex);
            }
        }
    };

    let handleDeleteRoom = async (index) => {
        const result = window.confirm("You are about to delete the Room.");
        if (result) {
            let room = rooms[index];
            try {
                const token = await fetchAccessToken();
                await deleteRoom(hotelId, room.roomNo, token)
                alert("Room details deleted successfully.");
                window.location.reload();
            } catch (ex) {
                alert(ex);
                alert("Hotel delete failed. Please contact support." + ex);
            }
        }
    };


    let onCloseRoomTypePopup = () => {
        setIsOpenRoomTypePopup(false);
    };
    let onCloseRoomPopup = () => {
        setIsOpenRoomPopup(false);
    };

    return (
        <div>
            <Card className="h-full w-full">
                <CardHeader variant="gradient" color="gray" className="mt-6 mb-8 p-6 flex flex-row justify-between">
                    <Typography variant="h6" color="white">
                        Room Category
                    </Typography>
                    <button className="shadow bg-blue-500 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white font-bold text-xs py-2 px-4 rounded" type="button"
                        onClick={handleOpenRoomTypePopup}>
                        Add Category
                    </button>
                </CardHeader>
                <CardBody className="overflow-scroll px-0 pt-0 pb-2">
                    <table className="mt-0 w-full min-w-max table-auto text-left">
                        <thead>
                            <tr>
                                {TABLE_HEAD_ROOM_TYPE.map((head) => (
                                    <th
                                        key={head}
                                        className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                                    >
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-90"
                                        >
                                            {head}
                                        </Typography>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {roomTypes.map(
                                ({ category, price, gst, capacity, extraBedCharges }, index) => {
                                    const isLast = index === roomTypes.length - 1;
                                    const classes = isLast
                                        ? "p-4"
                                        : "p-4 border-b border-blue-gray-50";

                                    return (
                                        <tr key={category}>
                                            <td className={classes}>
                                                <div className="flex items-center gap-3">
                                                    <div className="flex flex-col">
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal"
                                                        >
                                                            {category}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <div className="flex flex-col">
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {price} /-
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <div className="flex flex-col">
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {gst} %
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    Adults: {capacity?.adults}
                                                    <br />
                                                    Kids: {capacity?.kids}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {extraBedCharges}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Tooltip content="Edit">
                                                    <IconButton variant="text" onClick={() => handleEditRoomType(index)}>
                                                        <HiPencil className="h-6 w-6" />
                                                    </IconButton>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    );
                                },
                            )}
                        </tbody>
                    </table>
                </CardBody>
            </Card>

            <Card className="h-full w-full">
                <CardHeader variant="gradient" color="gray" className="mt-6 mb-8 p-6 flex flex-row justify-between">
                    <Typography variant="h6" color="white">
                        Rooms
                    </Typography>
                    <button className="shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold text-xs py-2 px-4 rounded" type="button"
                        onClick={handleOpenRoomPopup}>
                        Add Room
                    </button>
                </CardHeader>
                <CardBody className="overflow-scroll px-0 pt-0 pb-2">
                    <table className="mt-0 w-full min-w-max table-auto text-left">
                        <thead>
                            <tr>
                                {TABLE_HEAD_ROOM.map((head) => (
                                    <th
                                        key={head}
                                        className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                                    >
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-90"
                                        >
                                            {head}
                                        </Typography>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {rooms.map(
                                ({ roomNo, category, price, capacity, gst, extraBedCharges }, index) => {
                                    const isLast = index === rooms.length - 1;
                                    const classes = isLast
                                        ? "p-4"
                                        : "p-4 border-b border-blue-gray-50";

                                    return (
                                        <tr key={roomNo}>
                                            <td className={classes}>
                                                <div className="flex items-center gap-3">
                                                    <div className="flex flex-col">
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal"
                                                        >
                                                            {roomNo}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <Tooltip content="Delete">
                                                    <IconButton variant="text" onClick={() => handleDeleteRoom(index)}>
                                                        <MdDelete className="h-6 w-6" />
                                                    </IconButton>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    );
                                },
                            )}
                        </tbody>
                    </table>
                </CardBody>
            </Card>

            {isOpenRoomTypePopup && <RoomCategoryPopup initialValues={initialValueRoomType} is isOpen={isOpenRoomTypePopup} onClose={onCloseRoomTypePopup} onSubmit={onSubmitRoomType} />}
            {isOpenRoomPopup && <RoomPopup initialValues={initialValueRoom} isOpen={isOpenRoomPopup} onClose={onCloseRoomPopup} onSubmit={onSubmitRoom} roomTypes={roomTypes} />}

            <Dialog open={isLoading} className="bg-transparent shadow-none" size="md">
                <DialogBody className="bg-transparent shadow-none flex justify-center">
                    <ClockLoader color="#2be60d" />
                </DialogBody>
            </Dialog>

        </div>
    )
}
