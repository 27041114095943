import React, { useEffect, useState } from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Dialog,
    DialogBody,
    Typography,
    Menu, MenuHandler, MenuItem, MenuList,
    IconButton
} from "@material-tailwind/react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import { useUserContext } from '../../context';
import { ClockLoader } from 'react-spinners';

const TABLE_HEAD_ROOM_TYPE = ["S.No", "Date & time", "Totals", "Actions", "Remarks"];

export default function Laundry() {
    const navigate = useNavigate();
    const { fetchAccessToken, defaultHotel } = useUserContext();
    const { getLaundries } = useApi();
    const [laundries, setLaundries] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(getTodayMinus15Days());
    const [endDate, setEndDate] = useState(getTodayDate());

    function getTodayDate() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    function getTodayMinus15Days() {
        const now = new Date();
        now.setDate(now.getDate() - 15);

        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }


    function getEpoch(date, time, seconds = 0) {
        const [hours, minutes] = time.split(':').map(Number);
        const combinedDate = new Date(date);
        combinedDate.setHours(hours);
        combinedDate.setMinutes(minutes);
        combinedDate.setSeconds(seconds);
        combinedDate.setMilliseconds(seconds * 100);

        return combinedDate.getTime();
    }

    useEffect(() => {
        let getLaundriesInternal = async (hotelId) => {
            setIsLoading(true);
            try {
                const token = await fetchAccessToken();
                const start = getEpoch(startDate, "00:00");
                const end = getEpoch(endDate, "23:59");
                const data = await getLaundries(hotelId, start, end, token);
                if (data && data.length) {
                    setLaundries(data);
                } else {
                    setLaundries([]);
                }
            } catch (ex) {
                alert(ex);

            } finally {
                setIsLoading(false);
            }
        }
        if (defaultHotel) {
            getLaundriesInternal(defaultHotel);
        }
    }, [defaultHotel]);

    let getLaundriesInternal = async () => {
        if (!defaultHotel) {
            return;
        }
        setIsLoading(true);
        try {
            const token = await fetchAccessToken();
            const start = getEpoch(startDate, "00:00");
            const end = getEpoch(endDate, "23:59");
            const data = await getLaundries(defaultHotel, start, end, token);
            if (data && data.length) {
                setLaundries(data);
            } else {
                setLaundries([]);
            }
        } catch (ex) {
            alert(ex);

        } finally {
            setIsLoading(false);
        }
    }

    let onMenuReturnClick = (basketId) => {
        navigate(`/manager/laundry/${basketId}/return`);
    };

    let onMenuViewClick = (basketId) => {
        navigate(`/manager/laundry/${basketId}/edit`);
    };

    const processData = (data) => {
        const itemMap = new Map();

        data.forEach((entry) => {
            [...entry.returnItems].forEach((item) => {
                if (itemMap.has(item.name)) {
                    const existingItem = itemMap.get(item.name);
                    existingItem.count += item.count;
                    existingItem.total += item.count * item.unitPrice;
                } else {
                    itemMap.set(item.name, {
                        name: item.name,
                        count: item.count,
                        unitPrice: item.unitPrice,
                        total: item.count * item.unitPrice,
                    });
                }
            });
        });

        return Array.from(itemMap.values());
    };

    const summaryData = processData(laundries);
    const totalAmount = summaryData.reduce((sum, item) => sum + item.total, 0);

    return (
        <div>
            <Card className="h-full w-full">
                <CardHeader variant="gradient" color="gray" className="mt-6 mb-8 p-6 flex flex-row justify-between">
                    <Typography variant="h6" color="white">
                        Laundries
                    </Typography>
                    <div>
                        <button className="shadow bg-purple-500 hover:bg-purple-700 focus:shadow-outline focus:outline-none text-white font-bold text-xs mr-4 py-2 px-4 rounded" type="button"
                            onClick={() => navigate("/manager/laundry/create", { state: {} })} >
                            Create Pickup
                        </button>
                        <button className="shadow bg-blue-500 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white font-bold text-xs py-2 px-4 rounded" type="button"
                            onClick={() => navigate("/manager/laundry-items", { state: {} })} >
                            Items
                        </button>
                    </div>
                </CardHeader>
                <CardBody className="overflow-scroll px-0 pt-0 pb-2">
                    <div className="flex flex-row flex-wrap">
                        <div className="w-full lg:w-4/12 px-4">
                            <div className="h-10 flex justify-between sm:justify-start">
                                <Typography className="mt-2">
                                    Start:
                                </Typography>
                                <div className="flex flex-row">
                                    <input type="date" className="border-0 ml-2 mr-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={startDate} onChange={(event) => setStartDate(event.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-4/12 px-4">
                            <div className="h-10 flex justify-between sm:justify-start">
                                <Typography className="mt-2">
                                    End:
                                </Typography>
                                <div className="flex flex-row">
                                    <input type="date" className="border-0  ml-2 mr-2  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={endDate} onChange={(event) => setEndDate(event.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-4/12 px-4">
                            <div className=" flex justify-end">
                                <button className="bg-blue-500 text-white hover:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150"
                                    type="button" onClick={getLaundriesInternal}>
                                    View
                                </button>
                            </div>
                        </div>
                    </div>
                    <table className="mt-0 w-full min-w-max table-auto text-left">
                        <thead>
                            <tr>
                                {TABLE_HEAD_ROOM_TYPE.map((head) => (
                                    <th
                                        key={head}
                                        className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                                    >
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-90"
                                        >
                                            {head}
                                        </Typography>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {laundries.sort((a, b) => b.createdOn - a.createdOn).map(
                                ({ status, createdOn, pickupDate, pickupTime, returnDate, returnTime, previousMissedItems, pickupItems, returnItems, remarks }, index) => {
                                    const isLast = index === laundries.length - 1;
                                    const classes = isLast
                                        ? "p-4"
                                        : "p-4 border-b border-blue-gray-50";

                                    return (
                                        <tr key={index} className={`${status === "PICKUP" ? "bg-red-100" : "bg-yellow-100"}`}>
                                            <td className={classes}>
                                                <div className="flex items-center gap-3">
                                                    <div className="flex flex-col">
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal"
                                                        >
                                                            {index + 1}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <div className="flex flex-col items-start">
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        Pickup: {pickupDate} {" "}{pickupTime}
                                                    </Typography>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        Drop: {returnDate} {" "}{returnTime}
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <div className="flex flex-col items-start">
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        Pickup: {pickupItems.reduce((sum, item) => sum + item.count, 0)}
                                                    </Typography>
                                                </div>
                                                <div className="flex flex-col items-start">
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        Drop: {returnItems.reduce((sum, item) => sum + item.count, 0)}
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <div className="flex flex-col">
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        <Menu>
                                                            <MenuHandler>
                                                                <IconButton variant="text">
                                                                    <BiDotsVerticalRounded className="h-6 w-6" />
                                                                </IconButton>
                                                            </MenuHandler>
                                                            <MenuList className="flex flex-col gap-2">
                                                                {status === "PICKUP" &&
                                                                    <MenuItem key={0} className="flex items-center gap-4 py-2 pl-2 pr-8" onClick={() => onMenuReturnClick(createdOn)}>
                                                                        <div className="flex flex-col gap-1">
                                                                            <Typography variant="small" color="gray" className="font-semibold">
                                                                                Return
                                                                            </Typography>
                                                                        </div>
                                                                    </MenuItem>
                                                                }
                                                                <MenuItem key={1} className="flex items-center gap-4 py-2 pl-2 pr-8" onClick={() => onMenuViewClick(createdOn)}>
                                                                    <div className="flex flex-col gap-1">
                                                                        <Typography variant="small" color="gray" className="font-semibold">
                                                                            {status === "PICKUP" ? "View / Edit" : "View"}
                                                                        </Typography>
                                                                    </div>
                                                                </MenuItem>
                                                            </MenuList>
                                                        </Menu>
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <div className="flex flex-col">
                                                    <pre>
                                                        {remarks
                                                            ? remarks.split(',').map((line, index) => (
                                                                <div key={index}>{line.trim()}</div>
                                                            ))
                                                            : ''}
                                                    </pre>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                },
                            )}
                        </tbody>
                    </table>
                    <div>
                        <h1>Summary</h1>
                        <table className="mt-0 w-full min-w-max table-auto text-left">
                            <thead>
                                <tr>
                                    <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">Item Name</th>
                                    <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">Quantity</th>
                                    <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">Unit Price</th>
                                    <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {summaryData.map((item, index) => (
                                    <tr key={index}>
                                        <td className="p-4 border-b border-blue-gray-50">{item.name}</td>
                                        <td className="p-4 border-b border-blue-gray-50">{item.count}</td>
                                        <td className="p-4 border-b border-blue-gray-50">{item.unitPrice}</td>
                                        <td className="p-4 border-b border-blue-gray-50">{item.total}.00</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td className="p-4 border-b border-blue-gray-50"></td>
                                    <td className="p-4 border-b border-blue-gray-50"></td>
                                    <td className="p-4 border-b border-blue-gray-50">Total</td>
                                    <td className="p-4 border-b border-blue-gray-50">{totalAmount}.00</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
            <Dialog open={isLoading} className="bg-transparent shadow-none" size="md">
                <DialogBody className="bg-transparent shadow-none flex justify-center">
                    <ClockLoader color="#2be60d" />
                </DialogBody>
            </Dialog>

        </div>
    );
}