import { useNavigate, useParams } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import { useUserContext } from '../../context';
import { useState, useEffect } from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Dialog,
    DialogBody,
    Typography
} from "@material-tailwind/react";
import { ClockLoader } from 'react-spinners';
export default function EditLaundry(props) {
    const { basketId } = useParams();
    const navigate = useNavigate();
    const { fetchAccessToken, defaultHotel } = useUserContext();
    const { getLaundryItems, getLaundry, saveLaundry } = useApi();
    const [laundry, setLaundry] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [laundryItems, setLaundryItems] = useState([]);

    useEffect(() => {

        let getLaundryInternal = async (hotelId, basketId) => {
            setIsLoading(true);
            try {
                const token = await fetchAccessToken();
                const lItems = await getLaundryItems(hotelId, token);
                const data = await getLaundry(hotelId, basketId, token);
                if (lItems && lItems.length) {
                    setLaundryItems(lItems);
                } else {
                    setLaundryItems([]);
                }
                if (data) {
                    setLaundry(data);
                } else {
                    setLaundry(null);
                    alert("Laundry basket doesn't exist.");
                    navigate("/manager/laundry");
                }
            } catch (ex) {
                alert("Something went wrong.");
            } finally {
                setIsLoading(false);
            }
        }
        if (defaultHotel && basketId) {
            getLaundryInternal(defaultHotel, basketId);
        }
    }, [defaultHotel, basketId]);

    let handleCanelInternal = () => {
        navigate("/manager/laundry");
    };

    function formatDate(daysAdd = 0) {
        const date = new Date();
        date.setDate(date.getDate() + daysAdd);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    function getCurrentTime(hoursAdd = 0) {
        const now = new Date();
        const hours = String(now.getHours() + hoursAdd).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return hours + ':' + minutes;
    }

    let handleSubmitInternal = async () => {
        try {
            setIsLoading(true);

            const token = await fetchAccessToken();
            await saveLaundry(defaultHotel, laundry, token);
            alert("Pickup has been updated.");
            navigate("/manager/laundry");
        } catch (ex) {
            alert("Error occurred while updating basket." + ex);
        } finally {
            setIsLoading(false);
        }
    }

    let getPreviousMissingItemCount = (itemName) => {
        const missingItem = laundry.previousMissedItems.find(item => item.name === itemName);
        if (missingItem) {
            return missingItem.count;
        }
        return 0;
    };

    let getItemCount = (itemName) => {
        const missingItem = laundry.pickupItems.find(item => item.name === itemName);
        if (missingItem) {
            return missingItem.count;
        }
        return 0;
    };

    let getReturnItemCount = (itemName) => {
        const missingItem = laundry.returnItems.find(item => item.name === itemName);
        if (missingItem) {
            return missingItem.count;
        }
        return 0;
    };

    let getMissingItemCount = (itemName) => {
        const previous = getPreviousMissingItemCount(itemName);
        const current = getItemCount(itemName);
        const r = getReturnItemCount(itemName) || 0;
        return previous + current - r;
    };


    let setItemCount = (itemName, count, unitCharge) => {
        const pickupItems = laundry.pickupItems;
        let updatedItems = [];
        const itemIndex = pickupItems.findIndex((item) => item.name === itemName);
        if (itemIndex !== -1) {
            updatedItems = [...pickupItems];
            updatedItems[itemIndex] = {
                ...updatedItems[itemIndex],
                count: count,
            };
        } else {
            updatedItems = [...pickupItems, { name: itemName, count: count, unitPrice: unitCharge }];
        }
        setLaundry({ ...laundry, pickupItems: updatedItems })
    };



    return (
        <div>
            <Card className="h-full w-full">
                <CardHeader variant="gradient" color="gray" className="mt-6 mb-8 p-6 flex flex-row justify-between">
                    <Typography variant="h6" color="white">
                    {laundry && laundry.status === "PICKUP" ? "View / Edit" : "View"}
                    </Typography>
                </CardHeader>
                <CardBody>
                    <div className="relative w-full mb-3">
                        <div className="relative w-full mb-3">
                            <div className="flex flex-wrap gap-1 items-center mb-3">
                                <div className="flex-1 min-w-[50px] border-r">
                                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Item Name</label>
                                </div>
                                <div className="flex-1 min-w-[50px] border-r">
                                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Arrears</label>
                                    +
                                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Pickup</label>
                                </div>
                                <div className="flex-1 min-w-[50px] border-r">
                                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Return <span className="text-red-500">*</span></label>
                                </div>
                                <div className="flex-1 min-w-[50px]">
                                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Missing</label>
                                </div>
                            </div>
                            {laundry && laundryItems.map((laundryItem, index) =>
                                <div className="flex flex-wrap gap-1 items-center mb-3" key={index}>
                                    <div className="flex-1 min-w-[50px] border-r">
                                        <Typography
                                            variant="small"
                                            className="flex items-center font-normal text-blue-gray-600"
                                        >
                                            {laundryItem.itemName}
                                        </Typography>
                                    </div>
                                    <div className="flex-1 min-w-[50px] border-r ">
                                        {laundry.status === "RETURN" && <label className="block text-blueGray-600 text-md font mt-1 ml-2">
                                            {getPreviousMissingItemCount(laundryItem.itemName)}
                                            {" + "}
                                            {getItemCount(laundryItem.itemName)}
                                        </label>
                                        }
                                        {laundry.status === "PICKUP" &&
                                            (<><label className="block text-blueGray-600 text-md font mt-1 ml-2">
                                                {getPreviousMissingItemCount(laundryItem.itemName)}
                                                {" + "}
                                            </label>
                                                <input type="number"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    value={getItemCount(laundryItem.itemName)} onChange={(event) => setItemCount(laundryItem.itemName, parseInt(event.target.value, 10), laundryItem.unitCharge)} />
                                            </>)

                                        }
                                    </div>
                                    <div className="flex-1 min-w-[50px] border-r">
                                        <label
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            {getReturnItemCount(laundryItem.itemName)}
                                        </label>
                                    </div>
                                    <div className="flex-1 min-w-[50px]">
                                        <label className="block text-blueGray-600 text-md font mt-1 ml-2">{getMissingItemCount(laundryItem.itemName)}</label>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="w-full flex justify-end  mb-0 py-6">
                            {laundry && laundry.status === "PICKUP" && <button className="bg-blue-500 text-white hover:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150" type="button" onClick={handleSubmitInternal}>
                                Update
                            </button>
                            }
                            <button className="bg-pink-500 text-white hover:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150" type="button" onClick={handleCanelInternal}>
                                Canel
                            </button>

                        </div>
                    </div>
                </CardBody>
            </Card>
            <Dialog open={isLoading} className="bg-transparent shadow-none" size="md">
                <DialogBody className="bg-transparent shadow-none flex justify-center">
                    <ClockLoader color="#2be60d" />
                </DialogBody>
            </Dialog>
        </div>
    );
};