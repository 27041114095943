import { Dialog, DialogBody, Typography } from '@material-tailwind/react';
import React, { useEffect, useState } from 'react';
import { ClockLoader } from 'react-spinners';
import SingleSelectChip from '../../components/single-select-chip';
import { useUserContext } from '../../context';
import { useApi } from '../../hooks/useApi';
import RoomCard from '../../widgets/cards/room-card';
import RoomShift from './room-shift';
const roomStatusOptions = [
    { label: 'Checked-In', value: 'checked-in' },
    { label: 'Vacant', value: 'vacant' },
    { label: 'Online', value: 'online' },
    { label: 'Free', value: 'free' },
    { label: 'Due For Checkout', value: 'due' },
    { label: 'All', value: 'all' },
];

export default function Dashboard() {

    const [statusType, setStatusType] = useState(roomStatusOptions[0]);
    const { fetchAccessToken, defaultHotel, assignments } = useUserContext();
    const { getCheckedInRooms, getCheckInCard, transferRoom, extendRoom, getCardsByCSV } = useApi();
    const [roomsData, setRoomsData] = useState([]);
    const [checkedInRooms, setCheckedInRooms] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showRoomShiftPopup, setShowRoomShiftPopup] = useState(false);
    const [roomShiftRequest, setRoomShiftRequest] = useState({});
    const [bookedCount, setBookedCount] = useState(0);

    useEffect(() => {

        let getCheckedInRoomsInternal = async (hotelId) => {
            setIsLoading(true);
            try {
                const token = await fetchAccessToken();
                const data = await getCheckedInRooms(hotelId, token);
                let hotel = assignments?.hotels.find(hotel => hotel.id.toLowerCase() === hotelId.toLowerCase());
                const totalRooms = hotel.rooms;
                const distinctObjectsSet = new Map();
                totalRooms.forEach(room => {
                    distinctObjectsSet.set(room.roomNo.toLowerCase(), {
                        status: getRoomStatus(""),
                        roomDetails: {
                            roomNo: room.roomNo,
                            category: "",
                            cardNo: "",
                            guestName: "",
                            guestMobile: "",
                            checkedInTime: "",
                            checkedOutTime: "",
                            bookingType: "",
                            days: "",
                            amountReceived: "",
                            amountDue: "",
                            reference: ""
                        }
                    });
                });
                if (data && data.length) {
                    const cardNos = [...new Set(data.map(checkedInRoom => checkedInRoom.cardNo))];
                    let cards = [];
                    if (cardNos && cardNos.length) {
                        cards = await getCardsByCSV(hotelId, cardNos, token);
                    }
                    data.forEach(checkedInRoom => {
                        distinctObjectsSet.set(checkedInRoom.roomNo.toLowerCase(), {
                            status: getRoomStatus(checkedInRoom.checkOutDateTime),
                            roomDetails: {
                                roomNo: checkedInRoom.roomNo,
                                category: checkedInRoom.category ?? "",
                                cardNo: checkedInRoom.cardNo,
                                guestName: checkedInRoom.guestName,
                                guestMobile: checkedInRoom.guestMobile,
                                checkedInTime: checkedInRoom.checkInDateTime,
                                checkedOutTime: checkedInRoom.checkOutDateTime,
                                bookingType: checkedInRoom.bookingType ? ` - ${checkedInRoom.bookingType}` : "",
                                days: getDateDifference(checkedInRoom.checkInDateTime, checkedInRoom.checkOutDateTime),
                                amountReceived: getCardPayment(cards, checkedInRoom.cardNo),
                                amountDue: getCardDue(cards, checkedInRoom.cardNo),
                                reference: getReference(cards, checkedInRoom.cardNo)
                            }
                        });
                    });
                    setCheckedInRooms(data);
                    setBookedCount(data.length);
                }
                setRoomsData(Array.from(distinctObjectsSet.values()));
            } catch (ex) {
                alert("Something went wrong.");
            } finally {
                setIsLoading(false);
            }
        }

        if (defaultHotel) {
            getCheckedInRoomsInternal(defaultHotel);
        }
    }, [defaultHotel]);

    const handleOptionChange = (option) => {
        setStatusType(option);
    };

    const getRoomStatus = (checkOutDateTime) => {
        if (!checkOutDateTime) {
            return "vacant";
        }
        const currentDate = new Date();
        currentDate.setHours(currentDate.getHours() + 2);
        const [day, month, year, hour, minute] = checkOutDateTime.split(/[\s:-]/);

        const checkout = new Date(year, month - 1, day, hour || 0, minute || 0);

        if (checkout <= currentDate) {
            return 'due';
        } else {
            return 'checked-in';
        }
    };

    function getCardPayment(cards, cardNo) {
        let amount = "";
        let card = cards.find(cp => cp.cardNo === cardNo);
        if (card) {
            return card.amountReceived + ".00"
        }
        return amount;
    }

    function getCardDue(cards, cardNo) {
        let amount = "";
        let card = cards.find(cp => cp.cardNo === cardNo);
        if (card) {
            return card.amountDue + ".00"
        }
        return amount;
    }

    function getReference(cards, cardNo) {
        let ref = "";
        let card = cards.find(c => c.cardNo === cardNo);
        if (card && card.reference) {
            ref = ` (${card.reference})`
        }
        return ref;
    }

    const onRoomShiftRequest = async (cardNo, roomNo) => {
        try {
            setIsLoading(true);
            const token = await fetchAccessToken();
            const checkInCard = await getCheckInCard(defaultHotel, cardNo, token);
            if (checkInCard && checkInCard.card && checkInCard.checkIns) {
                let shiftRequest = {
                    card: checkInCard.card,
                    checkIns: checkInCard.checkIns
                };
                let hotel = assignments?.hotels.find(hotel => hotel.id.toLowerCase() === defaultHotel.toLowerCase());
                shiftRequest.roomTypes = hotel.roomTypes;
                shiftRequest.roomNoToChange = roomNo;
                shiftRequest.availableRooms = roomsData.filter(rd => rd.status === "vacant").map(rd => rd.roomDetails.roomNo);
                setRoomShiftRequest(shiftRequest);
                setShowRoomShiftPopup(true);
            }
        } catch (ex) {
            alert("Something went wrong.");
        } finally {
            setIsLoading(false);
        }
    };

    const extendRoomInternal = async (cardNo, roomNo) => {
        try {
            setIsLoading(true);
            const token = await fetchAccessToken();
            let checkIn = checkedInRooms.find(item => item.roomNo.toLowerCase() === roomNo.toLowerCase());
            if (checkIn) {
                checkIn.checkOutDateTime = addOneDay(checkIn.checkOutDateTime);
                checkIn.checkOutEpoch = addOneDayEpoch(checkIn.checkOutEpoch);
                await extendRoom(defaultHotel, cardNo, checkIn, token);
                alert("Room extended successfully.")
                window.location.reload();
            }
        }
        catch (ex) {
            alert("Something went wrong.");
        } finally {
            setIsLoading(false);
        }
    }

    function addOneDay(checkOutDateTime) {
        var parts = checkOutDateTime.split(/[\s-:]/);
        var originalDate = new Date(parts[2], parts[1] - 1, parts[0], parts[3], parts[4]);

        var nextDay = new Date(originalDate);
        nextDay.setDate(originalDate.getDate() + 1);

        var dd = String(nextDay.getDate()).padStart(2, '0');
        var mm = String(nextDay.getMonth() + 1).padStart(2, '0');
        var yyyy = nextDay.getFullYear();
        var HH = String(nextDay.getHours()).padStart(2, '0');
        var MM = String(nextDay.getMinutes()).padStart(2, '0');

        return dd + '-' + mm + '-' + yyyy + ' ' + HH + ':' + MM;
    }

    function getDateDifference(dateString1, dateString2) {
        const [day1, month1, year1, hour1, minute1] = dateString1.split(/[\s:-]/);
        const [day2, month2, year2, hour2, minute2] = dateString2.split(/[\s:-]/);

        const date1 = new Date(year1, month1 - 1, day1, hour1 || 0, minute1 || 0);
        const date2 = new Date(year2, month2 - 1, day2, hour2 || 0, minute2 || 0);

        const differenceMs = Math.abs(date2 - date1);

        const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

        if (differenceDays === 0) {
            return 1;
        } else {
            return differenceDays;
        }
    }

    function addOneDayEpoch(checkOutEpoch) {
        var originalDate = new Date(checkOutEpoch);
        var nextDay = new Date(originalDate);
        nextDay.setDate(originalDate.getDate() + 1);
        return nextDay.getTime();
    }

    let handleRoomShiftRequestInternal = async (updatedCard, newCheckIns, obsoleteCheckIns) => {
        try {
            setShowRoomShiftPopup(false);
            setIsLoading(true);
            const token = await fetchAccessToken();
            const request = { card: updatedCard, checkIns: newCheckIns, obsoleteCheckIns };
            await transferRoom(defaultHotel, updatedCard.cardNo, request, token);
            alert("Guest room shift suceesful.")
            window.location.reload();
        } catch (ex) {
            console.log(ex);
            alert("Something went wrong.");
        } finally {
            setIsLoading(false);
            setRoomShiftRequest({});
        }
    }

    let handleRoomShiftCanelInternal = () => {
        setShowRoomShiftPopup(false);
        setRoomShiftRequest({});
    };

    return (
        <div className="relative flex flex-col min-w-0 break-words w-full pt-3 mb-6 shadow-lg rounded-lg bg-blueGray-100 border border-blue-gray-100 border-1">
            <div className="flex flex-col">
                <div className="flex flex-col">
                    <Typography className="">
                        Filter by:
                    </Typography>
                    <div className="ml-5">
                        <SingleSelectChip options={roomStatusOptions} onChange={handleOptionChange} />
                    </div>
                </div>
            </div>
            <div className="flex p-2">
                    Vacant: {roomsData.length - bookedCount} {"  , "} Occupied: {bookedCount}
                </div>
            <div className="mt-5 grid sm:grid-cols-2 lg:grid-cols-3 gap-3">
                {roomsData.map((room, i) => {
                    if (statusType.value === "all" || statusType.value === room.status || (["online", "free"].includes(statusType.value) && room.roomDetails.bookingType.toLowerCase().includes(statusType.value)))
                        return <RoomCard key={i} {...room} onRoomShiftRequest={onRoomShiftRequest} onRoomExtend={extendRoomInternal} />
                    return null
                })}
            </div>
            {showRoomShiftPopup && <RoomShift isOpen={showRoomShiftPopup}
                onClose={handleRoomShiftCanelInternal} onSubmit={handleRoomShiftRequestInternal} roomShiftRequest={roomShiftRequest} />}
            {isLoading && <Dialog open={isLoading} className="bg-transparent shadow-none" size="md">
                <DialogBody className="bg-transparent shadow-none flex justify-center">
                    <ClockLoader color="#2be60d" />
                </DialogBody>
            </Dialog>}
        </div>
    )
}
