import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../context/userContext';
import LogoIcon from './images/white_logo.svg';
import BannerIcon from './images/banner.svg';
import GuestIcon from './images/guest.svg';
import HotelIcon from './images/hotel.svg';
import EmployeeIcon from './images/employee.svg';
import AttendanceIcon from './images/attendance2.svg';
import CashIcon from './images/cash.svg';
import ExpenseIcon from './images/expense.svg';
import DhobiIcon from './images/dhobi.svg';
import TransactionIcon from './images/transaction.svg';
import PreventionIcon from './images/prevention.svg';
import InsightsIcon from './images/insights.svg';
import FeedbackIcon from './images/feedback.svg';
import WhatsAppIcon from './images/whastapp.svg';
import ClientIcon1 from './images/g_hotel_logo.svg';
import ClientIcon2 from './images/arka.png';
import ClientIcon3 from './images/sre.png';
import ClientIcon4 from './images/srg.png';

export default function Home() {
    const navigate = useNavigate();
    const { login, isLoading, isAuthenticated, isAdmin } = useUserContext();

    const [dashboardUrl, setDashboardUrl] = useState("/manager/dashboard");
    useEffect(() => {
        if (isAuthenticated && isAdmin) {
            setDashboardUrl('/admin/dashboard');
        }
    }, [isAuthenticated, isAdmin]);
    return (
        <body className="text-gray-900">
            <section className="py-4 bg-slate-700 border-b-slate-100">
                <div className="container max-w-screen-xl px-4 mx-auto">
                    <nav className="flex flex-wrap items-center justify-between space-y-3 md:space-y-0 md:justify-between md:flex-nowrap"                    >
                        <a className="text-xl font-normal text-white lg:text-2xl" href="#"                        >
                            <img src={LogoIcon} alt="logo" className="w-48" />
                        </a>
                        {!isLoading && !isAuthenticated &&
                            <a
                                href="#" onClick={(event) => {
                                    event.preventDefault();
                                    login();
                                }}
                                className="px-4 py-2 font-medium transition duration-500 ease-in-out bg-white rounded-md text-emerald-600 text-md hover:bg-emerald-700 hover:text-white lg:mb-0"
                            >
                                <i className="fas fa-sign-in"></i> Login</a>
                        }
                        {!isLoading && isAuthenticated &&
                            <a
                                href="#" onClick={(event) => {
                                    event.preventDefault();
                                    navigate(dashboardUrl);
                                }}
                                className="px-4 py-2 font-medium transition duration-500 ease-in-out bg-white rounded-md text-emerald-600 text-md hover:bg-emerald-700 hover:text-white lg:mb-0"
                            >
                                <i className="fas fa-sign-in"></i> Account</a>
                        }
                    </nav>
                </div>
            </section>
            <section className="py-8 xl:py-12 lg:py-16 banner bg-emerald-600">
                <div className="container max-w-screen-xl px-4 mx-auto">
                    <div
                        className="flex flex-col items-center justify-between space-y-8 xl:space-y-0 md:space-x-0 lg:space-x-16 md:space-y-0 lg:flex-row"
                    >
                        <div className="w-full text-center lg:text-left">
                            <h1
                                className="text-3xl font-semibold leading-normal text-white md:text-3xl lg:text-3xl"
                            >
                                Offers cloud powered Hotel Management System
                            </h1>
                            <p
                                className="mt-6 mb-12 font-normal leading-normal text-emerald-100 text-md md:text-xl"
                            >
                                InnPro is a mobile friendly hotel management system that
                                simplifies hotel operations and improves guest interactions. Its
                                easy-to-use platform makes hotel management efficient and offers
                                customized experiences on any device.
                            </p>
                            <a
                                href="https://wa.me/+917842396731?text=Hi" target="_blank" rel="noopener noreferrer"
                                className="px-4 py-2 mb-5 font-medium transition duration-500 ease-in-out bg-white rounded-md text-emerald-900 text-md hover:bg-emerald-700 hover:text-white lg:mb-0"
                            ><i className="mr-2 fas fa-phone-alt"></i> Contact Us</a>
                        </div>
                        <div className="w-full">
                            <img src={BannerIcon} alt="Image" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-8">
                <div className="container max-w-screen-xl px-4 mx-auto">
                    <p
                        className="w-full my-8 text-2xl font-semibold text-center capitalize text-slate-800"
                    >
                        Our Clients
                    </p>
                    <div className="flex flex-wrap items-center justify-center">
                        <div className="flex justify-center w-1/2 p-2 lg:w-1/5 md:w-1/3">
                            <img
                                src={ClientIcon1}
                                alt="G Hotel Kakinada"
                                className="w-40" />
                        </div>
                        <div className="flex justify-center w-1/2 p-2 lg:w-1/5 md:w-1/3">
                            <img
                                src={ClientIcon2}
                                alt="ARKA Kakinada"
                                className="w-40" />
                        </div>
                        <div className="flex justify-center w-1/2 p-2 lg:w-1/5 md:w-1/3">
                            <img
                                src={ClientIcon3}
                                alt="SR Executive Kakinada"
                                className="w-40" />
                        </div>
                        <div className="flex justify-center w-1/2 p-2 lg:w-1/5 md:w-1/3">
                            <img
                                src={ClientIcon4}
                                alt="SR Grand Kakinada"
                                className="w-40" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-16 bg-gray-50">
                <div className="container max-w-screen-xl px-4 mx-auto">
                    <div className="w-full mx-auto text-center lg:w-2/3">
                        <h1
                            className="text-3xl font-semibold leading-normal text-slate-800 lg:text-3xl"
                        >
                            Cloud powered Hotel Management System
                        </h1>
                        <p
                            className="mt-6 mb-12 font-normal leading-normal text-slate-600 text-md md:text-2xl"
                        >
                            Mobile friendly solution offering wide range of features to simplify
                            and streamline hotel operations
                        </p>
                    </div>
                    <div
                        className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
                    >
                        <div
                            className="relative p-6 pb-8 transition duration-300 transform bg-white border border-2 border-gray-200 rounded-lg shadow-lg hover:border-emerald-300 hover:shadow-xl group"
                        >
                            <div className="h-28">
                                <div
                                    className="mb-4 text-6xl transition-transform duration-300 transform text-slate-500 group-hover:scale-110 flex justify-center"
                                >
                                    <img
                                        src={GuestIcon}
                                        alt="guest management"
                                        className="w-20"
                                    />
                                </div>
                                <h3 className="mb-2 text-lg font-semibold">Guest Management</h3>
                            </div>

                            <p className="mt-4 mb-4 text-gray-600">
                                Guest Management streamlines guest handling by managing profiles,
                                preferences, and interactions to enhance personalized service and
                                improve the overall guest experience.
                            </p>
                            <a href="#" className="absolute underline my-4 transition-colors text-emerald-600 bottom-2">Read More</a>
                        </div>

                        <div
                            className="relative p-6 pb-8 transition duration-300 transform bg-white border border-2 border-gray-200 rounded-lg shadow-lg hover:border-emerald-300 hover:shadow-xl group"
                        >
                            <div className="h-28">
                                <div
                                    className="mb-4 text-6xl transition-transform duration-300 transform text-slate-500 group-hover:scale-110 flex justify-center"
                                >
                                    <img
                                        src={HotelIcon}
                                        alt="hotel management"
                                        className="w-20"
                                    />
                                </div>
                                <h3 className="mb-2 text-lg font-semibold">Booking Management</h3>
                            </div>

                            <p className="mt-4 mb-4 text-gray-600">
                                Simplifies the reservation process by allowing efficient room
                                selection, availability tracking, and booking management to ensure
                                a seamless experience for both guests and staff.
                            </p>
                            <a href="#" className="absolute underline my-4 transition-colors text-emerald-600 bottom-2">Read More</a>
                        </div>

                        <div
                            className="relative p-6 pb-8 transition duration-300 transform bg-white border border-2 border-gray-200 rounded-lg shadow-lg hover:border-emerald-300 hover:shadow-xl group"
                        >
                            <div className="h-28">
                                <div
                                    className="mb-4 text-6xl transition-transform duration-300 transform text-slate-500 group-hover:scale-110 flex justify-center"
                                >
                                    <img
                                        src={EmployeeIcon}
                                        alt="employee management"
                                        className="w-20"
                                    />
                                </div>
                                <h3 className="mb-2 text-lg font-semibold">Empoloyee Management</h3>
                            </div>

                            <p className="mt-4 mb-4 text-gray-600">
                                Employee Management streamlines the administration of staff roles,
                                schedules, and performance tracking to enhance operational
                                efficiency and support effective team collaboration in the hotel.
                            </p>
                            <a href="#" className="absolute underline my-4 transition-colors text-emerald-600 bottom-2">Read More</a>
                        </div>

                        <div
                            className="relative p-6 pb-8 transition duration-300 transform bg-white border border-2 border-gray-200 rounded-lg shadow-lg hover:border-emerald-300 hover:shadow-xl group"
                        >
                            <div className="h-28">
                                <div
                                    className="mb-4 text-6xl transition-transform duration-300 transform text-slate-500 group-hover:scale-110 flex justify-center"
                                >
                                    <img src={AttendanceIcon} alt="Attedance" className="w-20" />
                                </div>
                                <h3 className="mb-2 text-lg font-semibold">Attendance</h3>
                            </div>

                            <p className="mt-4 mb-4 text-gray-600">
                                The Attendance module enables efficient tracking of employee work
                                hours, schedules, and attendance records, facilitating accurate
                                payroll management and ensuring optimal staffing levels in the
                                hotel.
                            </p>
                            <a href="#" className="absolute underline my-4 transition-colors text-emerald-600 bottom-2">Read More</a>
                        </div>

                        <div
                            className="relative p-6 pb-8 transition duration-300 transform bg-white border border-2 border-gray-200 rounded-lg shadow-lg hover:border-emerald-300 hover:shadow-xl group"
                        >
                            <div className="h-28">
                                <div
                                    className="mb-4 text-6xl transition-transform duration-300 transform text-slate-500 group-hover:scale-110 flex justify-center"
                                >
                                    <img src={CashIcon} alt="cash registry" className="w-20" />
                                </div>
                                <h3 className="mb-2 text-lg font-semibold">Cash Registry</h3>
                            </div>

                            <p className="mt-4 mb-4 text-gray-600">
                                The Cash Registry feature allows for streamlined management of
                                cash transactions, providing real-time tracking of revenues and
                                expenses to ensure accurate financial reporting and enhance
                                accountability in hotel operations.
                            </p>
                            <a href="#" className="absolute underline my-4 transition-colors text-emerald-600 bottom-2">Read More</a>
                        </div>

                        <div
                            className="relative p-6 pb-8 transition duration-300 transform bg-white border border-2 border-gray-200 rounded-lg shadow-lg hover:border-emerald-300 hover:shadow-xl group"
                        >
                            <div className="h-28">
                                <div
                                    className="mb-4 text-6xl transition-transform duration-300 transform text-slate-500 group-hover:scale-110 flex justify-center"
                                >
                                    <img
                                        src={ExpenseIcon}
                                        alt="Expense Management"
                                        className="w-20"
                                    />
                                </div>
                                <h3 className="mb-2 text-lg font-semibold">Expense Management</h3>
                            </div>

                            <p className="mt-4 mb-4 text-gray-600">
                                Expense Management simplifies the tracking and categorization of
                                hotel expenditures, enabling efficient budgeting and financial
                                oversight to optimize operational costs and improve profitability.
                            </p>
                            <a href="#" className="absolute underline my-4 transition-colors text-emerald-600 bottom-2">Read More</a>
                        </div>

                        <div
                            className="relative p-6 pb-8 transition duration-300 transform bg-white border border-2 border-gray-200 rounded-lg shadow-lg hover:border-emerald-300 hover:shadow-xl group"
                        >
                            <div className="h-28">
                                <div className="mb-4 text-6xl transition-transform duration-300 transform text-slate-500 group-hover:scale-110 flex justify-center" >
                                    <img
                                        src={DhobiIcon}
                                        alt="Dhobi Management"
                                        className="w-20"
                                    />
                                </div>
                                <h3 className="mb-2 text-lg font-semibold">Dhobi Management</h3>
                            </div>

                            <p className="mt-4 mb-4 text-gray-600">
                                Dhobi Management streamlines the laundry service operations by
                                efficiently tracking and managing laundry requests, schedules, and
                                inventory, ensuring timely service and maintaining high standards
                                of cleanliness for hotel linens and guest garments.
                            </p>
                            <a href="#" className="absolute underline my-4 transition-colors text-emerald-600 bottom-2">Read More</a>
                        </div>

                        <div
                            className="relative p-6 pb-8 transition duration-300 transform bg-white border border-2 border-gray-200 rounded-lg shadow-lg hover:border-emerald-300 hover:shadow-xl group"
                        >
                            <div className="h-28">
                                <div
                                    className="mb-4 text-6xl transition-transform duration-300 transform text-slate-500 group-hover:scale-110 flex justify-center"
                                >
                                    <img
                                        src={TransactionIcon}
                                        alt="transaction reports"
                                        className="w-20"
                                    />
                                </div>
                                <h3 className="mb-2 text-lg font-semibold">Transaction Reports</h3>
                            </div>

                            <p className="mt-4 mb-4 text-gray-600">
                                Transaction Reports provide detailed insights into financial
                                activities by tracking all revenue and expenditure transactions,
                                enabling hotels to analyze performance, identify trends, and make
                                informed financial decisions.
                            </p>
                            <a href="#" className="absolute underline my-4 transition-colors text-emerald-600 bottom-2">Read More</a>
                        </div>

                        <div
                            className="relative p-6 pb-8 transition duration-300 transform bg-white border border-2 border-gray-200 rounded-lg shadow-lg hover:border-emerald-300 hover:shadow-xl group"
                        >
                            <div className="h-28">
                                <div
                                    className="mb-4 text-6xl transition-transform duration-300 transform text-slate-500 group-hover:scale-110 flex justify-center"
                                >
                                    <img
                                        src={PreventionIcon}
                                        alt="Fraud prevention"
                                        className="w-20"
                                    />
                                </div>
                                <h3 className="mb-2 text-lg font-semibold">Fraud Prevention</h3>
                            </div>

                            <p className="mt-4 mb-4 text-gray-600">
                                Fraud Prevention enhances security by sending real-time
                                notifications for all financial transactions and room check-ins or
                                check-outs, ensuring transparency and enabling prompt action
                                against suspicious activities in the hotel.
                            </p>
                            <a href="#" className="absolute underline my-4 transition-colors text-emerald-600 bottom-2">Read More</a>
                        </div>

                        <div
                            className="relative p-6 pb-8 transition duration-300 transform bg-white border border-2 border-gray-200 rounded-lg shadow-lg hover:border-emerald-300 hover:shadow-xl group"
                        >
                            <div className="h-28">
                                <div
                                    className="mb-4 text-6xl transition-transform duration-300 transform text-slate-500 group-hover:scale-110 flex justify-center"
                                >
                                    <img src={InsightsIcon} alt="insights" className="w-20" />
                                </div>
                                <h3 className="mb-2 text-lg font-semibold">Hotels Insight</h3>
                            </div>

                            <p className="mt-4 mb-4 text-gray-600">
                                Hotels Insight provides a comprehensive overview of all hotel
                                operations, offering a bird's-eye view of performance metrics,
                                occupancy rates, and other key statistics to facilitate informed
                                decision-making and strategic planning.
                            </p>
                            <a href="#" className="absolute underline my-4 transition-colors text-emerald-600 bottom-2">Read More</a>
                        </div>

                        <div
                            className="relative p-6 pb-8 transition duration-300 transform bg-white border border-2 border-gray-200 rounded-lg shadow-lg hover:border-emerald-300 hover:shadow-xl group"
                        >
                            <div className="h-28">
                                <div
                                    className="mb-4 text-6xl transition-transform duration-300 transform text-slate-500 group-hover:scale-110 flex justify-center"
                                >
                                    <img src={FeedbackIcon} alt="feedback" className="w-20" />
                                </div>
                                <h3 className="mb-2 text-lg font-semibold">Guest Connect</h3>
                            </div>

                            <p className="mt-4 mb-4 text-gray-600">
                                Guest Connect fosters engagement by sending feedback messages and
                                promotional codes, enhancing communication with guests and
                                encouraging loyalty through personalized offers and insights.
                            </p>
                            <a href="#" className="absolute underline my-4 transition-colors text-emerald-600 bottom-2">Read More</a>
                        </div>

                        <div
                            className="relative p-6 pb-8 transition duration-300 transform bg-white border border-2 border-gray-200 rounded-lg shadow-lg hover:border-emerald-300 hover:shadow-xl group"
                        >
                            <div className="h-28">
                                <div
                                    className="mb-4 text-6xl transition-transform duration-300 transform text-slate-500 group-hover:scale-110 flex justify-center"
                                >
                                    <img src={WhatsAppIcon} alt="feedback" className="w-20" />
                                </div>
                                <h3 className="mb-2 text-lg font-semibold">
                                    Admin Notification
                                </h3>
                            </div>

                            <p className="mt-4 mb-4 text-gray-600">
                                Administrator Notification ensures that hotel administrators
                                receive timely WhatsApp alerts for all significant events within
                                the hotel, enabling prompt responses and efficient management of
                                operations.
                            </p>
                            <a href="#" className="absolute underline my-4 transition-colors text-emerald-600 bottom-2">Read More</a>
                        </div>
                    </div>
                </div>
            </section>

            <footer className="py-7 bg-slate-800 rooms_section">
                <div className="container max-w-screen-xl px-4 mx-auto">
                    <div className="flex flex-col items-center gap-16 lg:flex-row">
                        <div className="w-full lg:w-2/3">
                            <span className="text-xl font-semibold text-white lg:text-2xl">
                                InnPro - Hotel Management System
                            </span>
                            <p className="mt-6 text-lg text-slate-300">
                                InnPro is a mobile-friendly hotel management system that
                                simplifies operations and improves guest interactions. Its
                                easy-to-use platform makes hotel management efficient and offers
                                customized experiences on any device.
                            </p>
                        </div>
                        <div className="w-full lg:w-1/3">
                            <div
                                className="flex flex-col py-4 space-y-4 text-blue-200 md:space-y-4"
                            >
                                <span className="text-md font-semibold text-white lg:text-md underline">
                                    Contact Us
                                </span>
                                <span>
                                    InnPro Software Solutions,
                                </span>
                                <span>
                                    N-902, PBEL City,
                                </span>
                                <span>
                                    Peeramcheruvu, Hydershahkote,
                                </span>
                                <span>
                                    Rangareddy, Telangana.
                                </span>
                                <span className="">
                                    <a href="tel:+917842396731"
                                    ><i className="fas fa-phone"></i> +91 78423 96731</a
                                    >
                                </span>
                                <span>
                                    <a href="mailto:support@innpro.in"
                                    ><i className="fas fa-envelope"></i> support@innpro.in</a>
                                </span>
                            </div>
                        </div>

                    </div>
                    <div className="text-base font-normal text-white flex items-center justify-center">
                        © 2024 InnPro Software Solutions
                    </div>
                </div>
            </footer>
        </body>
    )
}
